<template>
  <div id="departmentCreate">
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <h5 class="text-dark font-weight-bold my-1 mr-5">
          {{ this.$t("admin.form.create_department") }}
        </h5>
        <b-button
          @click="$router.push({ name: 'DepartmentsList' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
        <!--end::Page Title-->
      </div>
      <!--end::Page Heading-->
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { validText } from "@/utils/validators";
import {
  createStakeholder,
  getDepartments
} from "@/api/admin/stakeholders.api";
import { mapActions } from "vuex";
import BasicForm from "@/components/form/BasicForm.component";
import { getError } from "@/utils/helpers";

export default {
  name: "DepartmentCreate",
  components: {
    BasicForm
  },
  data() {
    return {
      department: {},
      loaded: false,
      errors: [],
      error: false,
      form: {},
      departments: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions("Admin", ["loadCompanySelected", "loadDepartments", "reset"]),
    callGetError(code, params) {
      return getError(code, params);
    },
    save(data) {
      this.isSubmitting = true;
      const formattedForm = {
        name: data.departmentName,
        stakeholderCompanyId: localStorage.getItem("managedCompanyId")
      };

      if (data.departmentParent) {
        formattedForm.stakeholderDepartmentParentId = data.departmentParent.id;
      }

      if (!this.errors.length) {
        createStakeholder("departments", formattedForm)
          .then(response => {
            this.$root.$bvToast.toast(
              this.$t("admin.form.success.create_department_body", {
                department: response.data.records.name
              }),
              {
                title: this.$t("admin.form.success.create_department_header"),
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center"
              }
            );
            this.loadDepartments();
            this.loadCompanySelected();
            this.$router.push({
              name: "DepartmentsList"
            });
            this.isSubmitting = false;
          })
          .catch(error => {
            this.errors.push({
              name: error.response.data.records.userMessage,
              code: error.response.data.records.applicationCode,
              entity: "department"
            });
            this.$root.$bvToast.toast(
              this.callGetError(error.response.data.records.applicationCode),
              {
                title: this.callGetError("create_department", {
                  department: this.form.departmentName
                }),
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center"
              }
            );
            this.isSubmitting = false;
          });
      } else {
        this.isSubmitting = false;
      }
    },
    restartFormData() {
      this.form.departmentName = "";
      this.form.departmentParent = "";
    }
  },
  computed: {
    fields() {
      return [
        {
          id: "departmentName",
          required: true,
          type: "text",
          validation: validText(this.form.departmentName),
          maxLength: 255,
          uppercase: true
        },
        {
          id: "departmentParent",
          type: "select",
          required: true,
          label: "label",
          options: this.departmentOptions
        }
      ];
    },
    departmentOptions() {
      let departments = [];
      _.each(this.departments, department => {
        departments.push({
          id: department.stakeholderId,
          label: department.name
        });
      });
      return departments;
    }
  },
  mounted() {
    axios.all([getDepartments(localStorage.getItem("managedCompanyId"))]).then(
      axios.spread(departmentsResponse => {
        this.departments = departmentsResponse.data.records;
        this.form = {
          departmentName: "",
          departmentParent: null
        };
        this.loaded = true;
      })
    );
  }
};
</script>
